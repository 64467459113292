import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Thread = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { threadID } = parsedLocation;

  useRedirectHandler(() => {
    if (threadID) {
      return `https://home.classdojo.com/#/messages/${threadID}`;
    } else {
      return `https://home.classdojo.com/#/`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Thread;
